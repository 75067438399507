import React, {useState, useRef, useEffect} from 'react';
import PinField from 'react-pin-field';
import ReactDOM from 'react-dom';
import './verifyemailmodal.scss';
import {Redirect} from 'react-router';
import {doRequest} from '../../api/requests';
import {resendCode, saveCredentials} from '../../api/security';
import {handleError} from "../../api/errorHandling";
import SuccessBox from "../../components/successbox/SuccessBox";

function VerifyEmailModal({email, routeChanged}) {
    const [vid, setVid] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const focusRef = useRef(null);
    const [successStatus, setSuccessStatus] = useState(false);
    let renderSuccess;
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }

        doRequest("POST", "/security/verify-email-step1", {email: email})
            .then(result => {
                setVid(result.data.vid);
            })
            .catch(error => {
                console.log("got error: ", error);
            });
    }, [email, focusRef]);

    const sendSecretCode = () => {

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }

        doRequest("POST", "/security/verify-email-step2", {vid: vid, code: secretCode})
            .then(result => {
                saveCredentials(result.data, () => {
                    routeChanged();
                    setRedirect(true);
                });
            })
            .catch(error => setErrorCode(error));
    };

    if (redirect) {
        return <Redirect to=""/>;
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }
    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return ReactDOM.createPortal(
        <div className="modal-container">
            <div className="ReactModal__Content">
                <div className="VerifyEmailModal-container">
                    <h1 className="VerifyEmailModal-title">Verify email</h1>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "16px"}}>
                        Please check the email you use to register and enter the 6 digit verification code.
                    </p>
                    <p className="VerifyEmailModal-body" style={{marginBottom: "16px"}}>
                        Did not received the email? Please check your spam folder or resend verification code.
                    </p>
                    {renderError}
                    {renderSuccess}
                    <div className="VerifyEmailModal-code-container">
                        <PinField ref={focusRef} length={6} className="VerifyEmailModal-code" onChange={setSecretCode}>
                        </PinField>
                    </div>
                    <button onClick={sendSecretCode} type="button" className="VerifyEmailModal-verifyButton">
                        Verify
                    </button>
                    <button onClick={() => resendCode(vid)} type="button"
                            className="VerifyEmailModal-resendCodeButton">
                        Resend code
                    </button>
                </div>
            </div>
        </div>, document.body
    );
}

export default VerifyEmailModal;
