import "./tutorialscreen.scss"
import {Helmet} from "react-helmet";
import React from 'react'
import Header from "../../../components/header/Header"
import SideNavbar from "../../../components/side-navbar/SideNavbar"
import {Link} from "react-router-dom";

export let tutorialTitles = [
    "Tutorial - Connecting your sensors.",
    "Tutorial - Configuring your sensors as sticks or feet.",
    "Tutorial - Renaming a sensor.",
    "Tutorial - Record a video of your playing.",
    "Tutorial - Save your video recording.",
    "Tutorial - Share your video recording."
];

export default function TutorialsScreen({routeChanged}) {
    return <>
        <Helmet>
            <title>Tutorials</title>
        </Helmet>

        <div className="TutorialsScreen-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div className="TutorialsScreen-mainContent">
                <p className="TutorialScreen-title">Tutorials</p>
                <p className="TutorialScreen-subtitle">Step-by-step tutorials on how to use our new Freedrum
                    App</p>
                <p className="TutorialScreen-info">Follow these step-by-step tutorials if you’re having trouble
                    using
                    the app.</p>
                {tutorialTitles.map((text, index) => (
                    <Link to={'/account/tutorial/' + (index + 1) } key={index}>
                        <div className="TutorialScreen-tutorialTitleContainer">
                            <p>{text}</p>
                            <img src={require('../../../assets/images/round-chevron.svg').default} alt=""/>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    </>

}
