import "./App.scss";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Overview from "./pages/account/beta-program/overview/Overview";
import FeedbackScreen from "./pages/account/beta-program/feedback/FeedbackScreen";
import AddDeviceProfileScreen from "./pages/account/beta-program/device-profile/AddDeviceProfileScreen";
import ProfileScreen from "./pages/account/settings/profile/ProfileScreen";
import AddFeedbackScreen from "./pages/account/beta-program/feedback/AddFeedbackScreen";
import DeviceProfileScreen from "./pages/account/beta-program/device-profile/DeviceProfileScreen";
import EditProfileScreen from "./pages/account/settings/profile/EditProfileScreen";
import HowToTestScreen from "./pages/account/beta-program/how-to-test/HowToTestScreen";
import TutorialsScreen from "./pages/account/tutorials/TutorialsScreen";
import RegisterScreenEmail from "./pages/registerscreen/RegisterScreenEmail";
import LoginScreenEmail from "./pages/loginscreen/LoginScreenEmail";
import Onboarding from "./pages/account/onboarding/Onboarding";
import OnboardingSuccess from "./pages/account/onboarding/OnboardingSuccess";
import DeleteAccountSuccess from "./pages/account/settings/profile/DeleteAccountSuccess";
import TutorialSliderScreen from "./pages/account/tutorials/TutorialSliderScreen";
import DeviceSuccessScreen from "./pages/account/beta-program/device-profile/DeviceSuccessScreen";
import {useEffect, useState} from "react";
import {getAccessToken, updateAccessToken} from "./api/security";
import {getAllLocalDevices} from "./api/deviceRequests";
import ScrollToTop from "./components/scrollToTop.js";
import axios from "axios";
import * as ReactGA from "react-ga";

function App() {
    const [hasDevices, setHasDevices] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);

    useEffect(() => {
        routeChanged();

        setShouldRedirectToLogin(false);

        axios.interceptors.request.use(async (request) => {

            // Reject call with error if token is missing
            return await getAccessToken() === null && request.headers['headers'] !== undefined ?
                Promise.reject("token missing") :
                request;
        })

        axios.interceptors.response.use(response => response, async error => {
            if ((error === 'token missing') ||
                (error.response === undefined || error.response.status === 500)) {
                localStorage.clear();
                setShouldRedirectToLogin(true);
                routeChanged();
            } else if (error.response.status === 403 && error.response.data.message === 'token-expired') {
                await updateAccessToken()
                let accessToken = await getAccessToken();
                if (accessToken) {
                    const parameters = {
                        method: error.response.config.method,
                        url: error.response.config.url,
                        data: error.response.config.data,
                        headers: {Authentication: accessToken}
                    }
                    let response = await axios(parameters);
                    return Promise.resolve(response);
                }
            }

            return typeof error === "string" ?
                Promise.reject(error) :
                Promise.reject(error?.response?.data?.message ?? "unknown error");
        });
    }, [shouldRedirectToLogin])

    function routeChanged() {
        console.log("route changed");
        setHasDevices(getAllLocalDevices().flat().length !== 0);
        setIsAuthenticated(getAccessToken() !== null);
    }

    function route({path, component, exact, isProtected, isOnboarding}) {
        return <Route exact={exact} path={path} render={() => {
            if (isProtected) {
                if (getAccessToken() === null) {
                    let to = path === "/" ? "/register" : "";
                    ReactGA.pageview(to);
                    return <Redirect to={to}/>
                } else {
                    if (getAllLocalDevices().flat().length !== 0 || isOnboarding) {
                        ReactGA.pageview(path);
                        return component
                    } else {
                        let to = "/account/get-started";
                        ReactGA.pageview(to);
                        return <Redirect to={to}/>
                    }
                }
            } else {
                ReactGA.pageview(path);
                return component
            }
        }}/>
    }

    return (
        <Router>
            <ScrollToTop />

            {shouldRedirectToLogin ? <Redirect to="/login"/> : <></>}
            <Switch>
                {route({
                    path: "/",
                    component: <Overview routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/register",
                    component: <RegisterScreenEmail routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: false,
                    isOnboarding: false
                })}
                {route({
                    path: "/login",
                    component: <LoginScreenEmail routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: false,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/get-started",
                    component: <Onboarding routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: true,
                    isOnboarding: true
                })}
                {route({
                    path: "/account/get-started/success",
                    component: <OnboardingSuccess routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: true
                })}
                {route({
                    path: "/account/beta-program/feedback",
                    component: <FeedbackScreen routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/beta-program/feedback/add",
                    component: <AddFeedbackScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/beta-program/device-profile",
                    component: <DeviceProfileScreen routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/beta-program/device-profile/add",
                    component: <AddDeviceProfileScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/beta-program/device-profile/success",
                    component: <DeviceSuccessScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/beta-program/how-to-test",
                    component: <HowToTestScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/settings/profile",
                    component: <ProfileScreen routeChanged={routeChanged}/>,
                    exact: true,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/settings/profile/edit",
                    component: <EditProfileScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/tutorials",
                    component: <TutorialsScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/account/tutorial/:id",
                    component: <TutorialSliderScreen routeChanged={routeChanged}/>,
                    exact: false,
                    isProtected: true,
                    isOnboarding: false
                })}
                {route({
                    path: "/delete-success",
                    component: <DeleteAccountSuccess/>,
                    exact: true,
                    isProtected: false,
                    isOnboarding: false
                })}
            </Switch>
        </Router>
    );
}


export default App;