import './forminput.scss'
import {useState} from "react";

function SelectInput({labelTitle, selectName, disabled, handleFormChange, options, backgroundColor}) {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = e => {
        setInputValue(e.target.value);
        handleFormChange(e.target);
    };

    return (
        <div className="FormInput-nameField">
            <label className="FormInput-nameLabel"
                   htmlFor="register-name"
                   style={{backgroundColor: backgroundColor}}>{labelTitle}</label>
            <select name={selectName}
                    disabled={disabled}
                    className="FormInput-nameInput"
                    value={inputValue}
                    onChange={handleInputChange}> {
                options.map(function (value, index) {
                    switch (selectName) {
                        case 'app-version':
                            return <option key={index} value={value.id}>
                                {value.version + (index === 0 ? " (Newest release)" : "")}
                            </option>

                        case 'device':
                            return <option key={index} value={value.id}>
                                {value.model.model}
                            </option>

                        case 'device-model':
                            return <option key={index} value={value.id}>
                                {value.model}
                            </option>

                        case 'device-model-ios':
                            return <option key={index} value={value.id}>
                                {value.model}
                            </option>

                        case 'device-model-android':
                            return <option key={index} value={value.id}>
                                {value.model}
                            </option>

                        default:
                            return {}

                    }

                })};
            </select>
        </div>
    );
}

export default SelectInput;
