import './sidenavbar.scss'
import {NavLink} from "react-router-dom";
import FreedrumLogo from '../../assets/images/freedrum-logo.svg'
import {Redirect, Link} from "react-router-dom";
import {MdClose} from "react-icons/md";
import {useState} from 'react';
import {logOut} from "../../api/userRequests";

function SideNavbar() {
    const [logoutSuccess, setLogoutSuccess] = useState(false);

    if(logoutSuccess) {
        return <Redirect to="/login"/>
    }

    return (
        <div id="SideNavbar-content">
            <div className="SideNavbar-container">
                <span className="SideNavbar-container__closeBtn" onClick={mobileNavHandler}><MdClose size={16}/></span>
                <div className="SideNavbar-freedrumLogo">
                    <Link to="">
                        <img src={FreedrumLogo} alt="Freedrum logo"/>
                    </Link>
                </div>
                <NavLink to=""
                         activeClassName="SideNavbar-active"
                         exact>
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Beta program</p>
                    </div>
                </NavLink>

                <NavLink to="/account/beta-program/feedback"
                         activeClassName="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-secondaryItem">Feedback</p>
                    </div>
                </NavLink>

                <NavLink to="/account/beta-program/device-profile"
                         activeClassName="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-secondaryItem">Device Profile</p>
                    </div>
                </NavLink>

                <NavLink to="/account/beta-program/how-to-test"
                         activeClassName="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-secondaryItem">How To Test</p>
                    </div>
                </NavLink>

                <NavLink to="/account/settings/profile">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Settings</p>
                    </div>

                </NavLink>

                <NavLink to="/account/settings/profile"
                         activeClassName="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-secondaryItem">Profile</p>
                    </div>
                </NavLink>

                <NavLink to="/account/tutorials"
                         activeClassName="SideNavbar-active">
                    <div className="SideNavbar-item">
                        <p className="SideNavbar-mainItem">Tutorials</p>
                    </div>
                </NavLink>

                <button className="SideNavbar-logoutBtn" onClick={() => logOut(setLogoutSuccess)} >
                    Log out
                </button>

                <div className="SideNavbar-footer">
                    <p>
                        Copyright © {new Date().getFullYear()}, Freedrum. <br/>
                        Freedrum is Registered in the U.S. Patent and Trademark Office
                    </p>
                </div>
            </div>
            <div className="SideNavbar-overlay" onClick={mobileNavHandler}/>
        </div>
    );
}

function mobileNavHandler() {
    let element = document.getElementById("SideNavbar-content");
    if (element.className === 'SideNavbar-content-visible') {
        element.className = '';
    } else {
        element.className = 'SideNavbar-content-visible';
    }
}

export default SideNavbar;
