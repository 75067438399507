import './feedbackscreen.scss'
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import Header from "../../../../components/header/Header";
import addButton from "../../../../assets/images/add-button.svg";
import infoButton from "../../../../assets/images/info-button.svg";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import RemoveFeedbackModal from "./RemoveFeedbackModal";
import {useEffect, useState} from "react";
import {requestAllFeedback} from "../../../../api/feedbackRequests";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import FeedbackItem from "../../../../components/feedback/FeedbackItem";
import chevron from "../../../../assets/images/flat-chevron.svg";

function FeedbackScreen({routeChanged}) {
    const [feedbackMap, setFeedbackMap] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [feedbackID, setFeedbackID] = useState("");

    useEffect(() => {
        requestAllFeedback(setFeedbackMap);
    }, []);

    function showRemoveModal(feedbackId) {
        setFeedbackID(feedbackId);
        setIsOpen(true);
    }

    return <>
        <Helmet>
            <title>Beta Program - Feedback</title>
        </Helmet>
        <div className="FeedbackScreen-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div id="FeedbackScreen-mainContent" className={
                (Object.keys(feedbackMap).length !== 0 &&
                    Object.keys(feedbackMap).some(item => {
                        return feedbackMap[item].length > 1
                    }))
                    ? "fullWidth" : ""
            }>
                <div className="FeedbackScreen-header">
                    <ReactTooltip type="light" border={false} place={"right"} multiline={true}/>
                    <p className="FeedbackScreen-title">
                        <span className="FeedbackScreen-title-span">Feedback</span>
                        <img src={infoButton} alt=""
                             data-tip="Private feedback, this<br />feedback only visible<br />for you and the<br />Freedrum team."
                             width="16px" height="16px"/>
                    </p>
                    <p className="FeedbackScreen-description">
                        Help us improve our product and leave a feedback and ratings.{" "}
                    </p>
                </div>
                <div className="FeedbackScreen-addButton">
                    <Link to="/account/beta-program/feedback/add">
                        <img src={addButton} alt=""/>
                    </Link>
                </div>

                {Object.keys(feedbackMap).reverse().map((key, index) =>
                    <div key={index} className="FeedbackScreen-feedbackContent">
                        <div id={`FeedbackScreen-feedback-versionInfo-${key}-${index}`}
                             className="FeedbackScreen-feedback-versionInfo">
                            <p className="FeedbackScreen-feedback-versionData">
                                <span className="FeedbackScreen-version">{feedbackMap[key][0].appVersion.version}</span>
                                <span id="FeedbackScreen-stars-container">
                                {[...new Array(5)].map((val, index) =>
                                    Math.round(feedbackMap[key].reduce((sum, value) => {
                                        return sum + value.rating;
                                    }, 0) / feedbackMap[key].length) <= index ?
                                        <AiOutlineStar key={index} size={20}/> :
                                        <AiFillStar key={index} size={20} color="#00A21F"/>)
                                }
                            </span>
                            </p>

                            <img id={`FeedbackScreen-chevron-collapsable-${key}-${index}`} src={chevron} alt=""
                                 className="FeedbackScreen-chevron-collapsable"
                                 onClick={() => toggleCollapse(key, index)}/>

                        </div>
                        <div className="FeedbackScreen-collapsable"
                             id={`FeedbackScreen-collapsable-${key}-${index}`}> {
                            feedbackMap[key].map((feedback, index) => <FeedbackItem
                                deleteFeedback={() => showRemoveModal(feedback.id)}
                                index={index}
                                key={index}
                                feedback={feedback}/>)
                        }
                        </div>
                        {feedbackMap[key].length === 0 &&
                        <p className="FeedbackScreen-noReview">No Reviews</p>
                        }
                    </div>
                )}

            </div>

            {modalIsOpen &&
            <RemoveFeedbackModal IsOpen={setIsOpen} feedbackID={feedbackID} setFeedbackMap={setFeedbackMap}/>}
        </div>
    </>
}

function toggleCollapse(key, index) {
    let collapsable = document.getElementById(`FeedbackScreen-collapsable-${key}-${index}`);
    let toggle = document.getElementById(`FeedbackScreen-chevron-collapsable-${key}-${index}`);
    let versionInfo = document.getElementById(`FeedbackScreen-feedback-versionInfo-${key}-${index}`);
    if (collapsable.className === 'FeedbackScreen-collapsable FeedbackScreen-collapsed') {
        collapsable.className = 'FeedbackScreen-collapsable';
        toggle.className = 'FeedbackScreen-chevron-collapsable';
        versionInfo.className = 'FeedbackScreen-feedback-versionInfo';
    } else {
        collapsable.className = 'FeedbackScreen-collapsable FeedbackScreen-collapsed';
        toggle.className = 'FeedbackScreen-chevron-collapsable FeedbackScreen-chevron-collapsed';
        versionInfo.className = 'FeedbackScreen-feedback-versionInfo FeedbackScreen-versionInfo-collapsed';
    }
}

export default FeedbackScreen;
