import './adddeviceprofilescreen.scss'
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import SelectInput from "../../../../components/forminput/SelectInput";
import {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Redirect} from "react-router";
import {requestAllDeviceModels} from "../../../../api/deviceModelRequests";
import {addDevice} from "../../../../api/deviceRequests";
import {handleError} from "../../../../api/errorHandling";

function AddDeviceProfileScreen({routeChanged}) {
    const [OS, setOS] = useState('iOS');
    const [devices, setDevices] = useState([[], []]);
    const [errorCode, setErrorCode] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(0);
    const [shouldRedirect, setShouldRedirect] = useState(null);

    let renderError;

    useEffect(() => {
        requestAllDeviceModels(setDevices);
    }, []);

    const addPhone = (event) => {
        event.preventDefault();

        if (selectedDevice === 0) {
            setErrorCode('missing-device');
            return;
        }

        setErrorCode(null);

        addDevice(selectedDevice, (result) => {
            if ((OS === 'iOS' && result[0].length === 1) ||
                (OS === 'Android' && result[1].length === 1)) {
                setShouldRedirect("/account/beta-program/device-profile/success");
            } else {
                setShouldRedirect("/account/beta-program/device-profile?added=true");
            }
        })
    };

    const OSHandler = (input) => {
        setOS(input.target.value)
        setSelectedDevice(0);
        setErrorCode(null);
    };

    const handleFormChange = (input) => {
        setSelectedDevice(input.value);
        setErrorCode(null);
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    if (shouldRedirect !== null) {
        return <Redirect to={shouldRedirect} push={true}/>
    }

    return <>
        <Helmet>
            <title>Beta Program - Add Device Profile</title>
        </Helmet>
        <div className="AddDeviceProfileScreen-container">
            <Header routeChanged={routeChanged}/>
            <SideNavbar/>
            <div className="AddDeviceProfileScreen-mainContent">
                <p className="AddDeviceProfileScreen-title">Add device</p>
                <p className="AddDeviceProfileScreen-info">Please select which version of our app you would like to
                    test. </p>
                <p className="AddDeviceProfileScreen-info-consent">By adding your device, you give us consent to invite
                    you to Apple’s or Google’s beta program.</p>
                {renderError}

                <p className='AddDeviceProfileScreen-form-title'>Operating system:</p>
                <div className="AddDeviceProfileScreen-radioButtons-container">
                    <label className="container">iOS
                        <input name="OS" type="radio" value="iOS" checked={OS === "iOS"} onChange={OSHandler}/>
                        <span className="checkmark"/>
                    </label>
                    <label className="container">Android
                        <input name='OS' type="radio" value="Android" checked={OS === "Android"}
                               onChange={OSHandler}/>
                        <span className="checkmark"/>
                    </label>
                </div>
                <p className="AddDeviceProfileScreen-form-title">Device Model:</p>
                <p className="AddDeviceProfileScreen-form-info">Please specify which model you are using.</p>
                <form className="AddDeviceProfileScreen-form-container" onSubmit={addPhone}>
                    <div className="AddDeviceProfileScreen-form-inputs">
                        <SelectInput selectName="device-model" labelTitle={OS} options={
                            OS === "iOS" ? devices[0] : devices[1]
                        } handleFormChange={handleFormChange}/>
                    </div>
                    <button id="AddDeviceProfileScreen-save" type="submit"
                            className="AddDeviceProfileScreen-save">
                        Save Device
                    </button>
                </form>
            </div>
        </div>
    </>
}

export default AddDeviceProfileScreen;
