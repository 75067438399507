import ReactDOM from 'react-dom';
import './removefeedbackmodal.scss';
import FocusTrap from "focus-trap-react";
import {removeFeedback} from "../../../../api/feedbackRequests";

const RemoveFeedbackModal = ({IsOpen, feedbackID, setFeedbackMap}) => {
    const closingModal = () => {
        IsOpen(false);
    }

    const removeItem = () => {
        removeFeedback(feedbackID, setFeedbackMap);
        IsOpen(false);
    }

    return ReactDOM.createPortal(
        <div className="modal-container">
            <FocusTrap>
                <div role="dialog" aria-modal="true" className="ReactModal__Content">
                    <div className="RemoveFeedbackModal-container">
                        <h1 className="RemoveFeedbackModal-title">Remove Feedback</h1>
                        <p className="RemoveFeedbackModal-body" style={{marginBottom: "0px"}}>Are you sure you want to
                            remove your feedback from the list? </p>
                        <button type="button" className="RemoveFeedbackModal-DeleteBtn" onClick={removeItem}>Delete
                        </button>
                        <button type="button" className="RemoveFeedbackModal-CloseBtn" onClick={closingModal}>Cancel
                        </button>
                    </div>
                </div>
            </FocusTrap>

        </div>, document.body
    );
};


export default RemoveFeedbackModal;
