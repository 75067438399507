import {doAuthenticatedRequest} from "./requests";

export function requestAllAppVersions(callback) {
    doAuthenticatedRequest("GET", "/app-versions", {})
        .then(result => {
            localStorage.setItem("all-app-versions", JSON.stringify(result.data.reverse()));
            callback(getAllLocalAppVersions());
        });
    callback(getAllLocalAppVersions());
}

function getAllLocalAppVersions() {
    let allAppVersions = localStorage.getItem("all-app-versions");
    return allAppVersions !== undefined && allAppVersions != null ? JSON.parse(allAppVersions) : [];
}