import "./onboarding.scss";
import SelectInput from "../../../components/forminput/SelectInput";
import {ReactComponent as FreedrumLogo} from '../../../assets/images/freedrum-logo.svg';
import {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {requestAllDeviceModels} from "../../../api/deviceModelRequests";
import {userRequests} from "../../../api/userRequests";
import {addDevice} from "../../../api/deviceRequests";
import {handleError} from "../../../api/errorHandling";

const Onboarding = ({routeChanged}) => {
    const [devices, setDevices] = useState([[], []]);
    const [operatingSystems, setOperatingSystems] = useState([]);
    const [usersName, setUsersName] = useState("");
    const [changePage, setChangePage] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState({});
    let renderError;

    useEffect(() => {
        requestAllDeviceModels(setDevices);
        userRequests((result) => {
            (result !== undefined ? setUsersName(result.name.split(" ")[0]) : setUsersName(""))
        })
    }, []);

    const savingDevice = (e) => {
        e.preventDefault();
        const keys = Object.keys(selectedDevices);

        if (keys.length === 0) {
            setErrorCode("missing-device")
        }

        keys.forEach((item) => {
            if (selectedDevices[item] !== 0) {
                addDevice(selectedDevices[item], () => {
                    routeChanged();
                    setChangePage(true);
                })
            }
        })
    }

    if (changePage) {
        return <Redirect to="/account/get-started/success"/>
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    function operatingSystemChecked(event, operatingSystem) {
        setOperatingSystems(event.target.checked ?
            operatingSystems.concat([operatingSystem]) :
            operatingSystems.filter(item => item !== operatingSystem));
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;
        let devices = selectedDevices;
        devices[inputName] = inputValue;
        setSelectedDevices(devices);
    }

    return <>
        <Helmet>
            <title>Get started</title>
        </Helmet>
        <div className="onboarding-container">
            <div className="onboarding-container-freedrumLogoContainer">
                <FreedrumLogo/>
            </div>
            <div className="onboarding-dialogBox-container">
                <h1>Welcome {usersName}!</h1>
                <p className="onboarding-dialogBox-container-text">
                    Glad to have you onboard. Before you start, please add your device below.
                </p>
                <p className="onboarding-dialogBox-container-consent">
                    By adding your device, you give us consent to invite you to Apple's and Google's beta program.
                </p>
                <div className="onboarding-dialogBox">
                    <form className="onboarding-dialogBox-form" onSubmit={savingDevice}>
                        {renderError}
                        <h2>Operating system:</h2>
                        <p className="onboarding-dialogBox-form-body">You can select both if you have two devices.</p>
                        <div className="onboarding-dialogBox-form-checkBoxes-container">
                            <div className="onboarding-dialogBox-form-checkBoxes">
                                <label className="onboarding-dialogBox-form-checkBoxes__label  one">
                                    <input
                                        onChange={(event) => operatingSystemChecked(event, 'iOS')}
                                        type="checkbox" name="ios" id="ios_checkbox"/>
                                    <div className="checkbox-custom first"/>
                                    <p className="checkbox-text first" htmlFor="ios_checkbox">
                                        iOS
                                    </p>
                                </label>
                            </div>
                            <div className="onboarding-dialogBox-form-checkBoxes">
                                <label className="onboarding-dialogBox-form-checkBoxes__label two">
                                    <input onChange={(event) => operatingSystemChecked(event, 'Android')}
                                           type="checkbox"
                                           name="android" id="android_checkbox"/>
                                    <div className="checkbox-custom second"/>
                                    <p className="checkbox-text second" htmlFor="android_checkbox">
                                        Android
                                    </p>
                                </label>
                            </div>
                        </div>
                        <h2>Device model:</h2>
                        <p className="onboarding-dialogBox-form-body">Please specify which model you are using.</p>
                        <div className="onboarding-dialogBox-form-inputs-container">
                            <div className="onboarding-dialogBox-form-inputs one">
                                <SelectInput disabled={operatingSystems.includes('iOS') ? '' : 'disabled'}
                                             selectName="device-model-ios" labelTitle="iOS" options={devices[0]}
                                             handleFormChange={handleFormChange} backgroundColor={"#F8F8F8"}/>
                            </div>
                            <div className="onboarding-dialogBox-form-inputs two">
                                <SelectInput disabled={operatingSystems.includes('Android') ? '' : 'disabled'}
                                             selectName="device-model-android" labelTitle="Android" options={devices[1]}
                                             handleFormChange={handleFormChange} backgroundColor={"#F8F8F8"}/>
                            </div>
                        </div>
                        <button type="submit" className="onboarding-dialogBox-form-Button" id="onboarding-button">
                            Add device
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </>
};

export default Onboarding;