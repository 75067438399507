import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './changepasswordmodal.scss';
import PinField from 'react-pin-field';
import FocusTrap from "focus-trap-react";
import {MdClose} from "react-icons/md";
import {Redirect} from "react-router-dom";
import {doAuthenticatedRequest, doRequest} from '../../../../api/requests';
import {handleError} from "../../../../api/errorHandling";
import {logOut} from "../../../../api/userRequests";
import SuccessBox from "../../../../components/successbox/SuccessBox";

const ChangeEmailAddressModal = ({openModal, setDisabledStatus, modalName, setModalName, emailModel, vid, routeChanged}) => {
    const focusRef = useRef(null);
    const [secretCode, setSecretCode] = useState("");
    const [backToLogin, setBackToLogin] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [successStatus, setSuccessStatus] = useState(false);
    let renderSuccess;
    let renderModal;
    let renderError;

    useEffect(() => {
        let currentElement = focusRef.current[0];
        if (currentElement !== undefined) {
            currentElement.focus();
        }
    }, [focusRef]);

    const secretCodeHandler = (code) => {
        setSecretCode(code);
        setErrorCode(null);
    };

    if (backToLogin) {
        return <Redirect to="/login"/>
    }

    const verificationHandler = (e) => {
        e.preventDefault();

        if (secretCode.length !== 6) {
            setErrorCode("form-missing-fields");
            return;
        }
        setErrorCode(null);

        doAuthenticatedRequest("POST", "/security/change-email-step2", {vid: vid, code: secretCode})
            .then(() => {
                setModalName("success");
            })
            .catch(error => setErrorCode(error));
    }

    const logoutClicked = () => {
        logOut(() => {
            routeChanged();
            setBackToLogin(true);
        });
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    if (successStatus) {
        renderSuccess = <SuccessBox text={<p>Code has been sent again.</p>} setSuccessStatus={setSuccessStatus}/>
    }

    const resendCode = (vid) => {
        doRequest("POST", "/security/resend-verification", {vid: vid})
            .then(() => {
                setErrorCode(null);
                setSuccessStatus(true);
            })
            .catch((error) => {
                setErrorCode(error);
                setSuccessStatus(false);
            });
    }

    const closeModal = () => {
        setDisabledStatus(false);
        openModal(false);
    }

    if (modalName === "verify") {
        renderModal = <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div className="ChangeEmailScreen-container">
                <div className="ChangeEmailScreen-container-closeButton">
                    <button className="ChangeEmailScreen-container-closeButton-image" onClick={closeModal}>
                        <MdClose className="ChangeEmailScreen-container-closeButton-image-icon"/>
                    </button>
                </div>
                <h1 className="ChangeEmailScreen-title">Change Email Address</h1>
                <p className="ChangeEmailScreen-body">To confirm this change, please enter the 6-digit verification code
                    we sent to <span className="ChangeEmailScreen-email">{emailModel.previousEmail}</span></p>
                <p className="ChangeEmailScreen-body">Did not receive the email? Please check your spam folder or resend
                    verification code.</p>
                {renderSuccess}
                {renderError}
                <div tabIndex="-1" className="ChangeEmailScreen-code-container">
                    <PinField ref={focusRef} length={6} className="ChangeEmailScreen-code" onChange={secretCodeHandler}>
                    </PinField>
                </div>
                <button onClick={verificationHandler} type="button" className="ChangeEmailScreen-verifyButton">
                    Verify
                </button>
                <button type="button" className="ChangeEmailScreen-resendCodeButton"
                        onClick={() => resendCode(vid)}>Resend code
                </button>
            </div>
        </div>
    } else if (modalName === "success") {
        renderModal = <div className="ReactModal__Content" role="dialog" aria-modal="true">
            <div ref={focusRef} tabIndex="-1" className="ChangeEmailScreen-container">
                <h1 className="ChangeEmailScreen-title">Email Address Changed</h1>
                <p className="ChangeEmailScreen-body" style={{marginBottom: "16px"}}>Your new email address has been
                    saved and the beta invitation has been sent to that address. If you have signed up to the beta on
                    both Android and iOS, we will send all necessary invitations to your inbox. The invitation can take
                    up to 48 hours before it arrives.

                </p>
                <p className="ChangeEmailScreen-body" style={{marginBottom: "8px"}}>
                    You will need to re-login to access your account.
                </p>
                <button onClick={logoutClicked} type="button" className="ChangeEmailScreen-resendCodeButton">
                    Back to log in
                </button>
            </div>
        </div>
    }

    return ReactDOM.createPortal(
        <FocusTrap>
            <div className="modal-container">
                {renderModal}
            </div>
        </FocusTrap>, document.body
    );
};

export default ChangeEmailAddressModal;
