import {doAuthenticatedRequest} from "./requests";

export function requestAllFeedback(callback) {
    doAuthenticatedRequest("GET", "/feedback", {})
        .then(result => {
            localStorage.setItem("all-feedback", JSON.stringify(result.data));
            callback(getAllLocalFeedback());
        });
    callback(getAllLocalFeedback());
}

export function removeFeedback(feedbackId, callback) {
    doAuthenticatedRequest("DELETE", "/feedback/" + feedbackId, {})
        .then(() => {
            let feedback = localStorage.getItem("all-feedback");
            if (feedback != null) {
                let parsedFeedback = JSON.parse(feedback);
                let filteredFeedback = parsedFeedback.filter(value => value.id !== feedbackId);
                localStorage.setItem("all-feedback", JSON.stringify(filteredFeedback));
            }
            callback(getAllLocalFeedback());
        });
}

export function addFeedback(feedback, callback, errorCallback) {
    doAuthenticatedRequest("POST", "/feedback", feedback)
        .then((result) => {
            let allFeedback = localStorage.getItem("all-feedback");
            if (allFeedback != null) {
                let array = JSON.parse(allFeedback).concat(result.data);
                localStorage.setItem("all-feedback", JSON.stringify(array));
            } else {
                localStorage.setItem("all-feedback", JSON.stringify([result.data]));
            }
            callback(getAllLocalFeedback());
        })
        .catch((error) => errorCallback(error))
}

function getAllLocalFeedback() {
    let feedback = localStorage.getItem("all-feedback");
    let jsonFeedback = feedback !== null ? JSON.parse(feedback) : [];
    return jsonFeedback.reverse().reduce((array, value) => {
        let id = value.appVersion.id;
        array[id] = array[id] === undefined ? [value] : [...array[id], value];
        return array;
    }, {});
}