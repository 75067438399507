import "./tutorialsliderscreen.scss"

import React from 'react'

import {useParams} from 'react-router';
import TutorialStep from "../../../components/tutorial-step/TutorialStep";
import Header from "../../../components/header/Header";
import SideNavbar from "../../../components/side-navbar/SideNavbar";
import rightArrow from "../../../assets/images/right-arrow.svg";
import {tutorialTitles} from "./TutorialsScreen";
import {Helmet} from "react-helmet";

export default function TutorialSliderScreen({routeChanged}) {
    const tutorial = [[
        "Access the Freedrum sensor settings by tapping the (  setting icon  )",
        "Turn on Bluetooth to connect the Freedrum sensors.",
        "Your device will automatically search for your Freedrum sensors, when found you will be directed to the next step.",
        "Tap on the sensor to pair it to your device.",
        "When paired, tap “Done” to see the status of your Freedrum sensors.",
        "Tap “Done” to close the window. Now you’re ready to play!",
    ], [
        "Access the Freedrum sensor settings by tapping the (  setting icon  )",
        "Identify a specific sensor by waving it around. The bar (  bar icon  ) will show which one it corresponds to.",
        "Tap on the sensor name to enter the configuration menu.",
        "Tap on the first option in the menu to change the sensor placement.",
        "Tap on the image to change from Stick to Foot sensor (or vice versa).",
        "Tap back to go out of the sensor placement menu. The change is saved automatically.",
        "The sensor placement is now successfully changed."
    ], [
        "Access the Freedrum sensor settings by tapping the (  setting icon  )",
        "Identify a specific sensor by waving it around. The bar (  bar icon  ) will show which one it corresponds to.",
        "Tap on the sensor name to enter the configuration menu.",
        "Tap on the edit button to change sensor name.",
        "Type new name for the sensor. You can clear the input bar by tapping the clear button on the far right.",
        "When done, tap “Go” to go back to the configuration menu.",
        "Tap back to go back to the sensor list and close the dialog box.",
    ], [
        "Activate your camera by tapping the camera button on the bottom of the screen.",
        "A new window will show up with the view of your front camera. Tap the record button to start recording.",
        "You can also switch your camera by typing the reverse camera icon on the right.",
        "Tap the stop button to stop the recording.",
        "The app will process your video for preview.",
        "When it’s done processing, you can (  save the video  ) or (  share it  )."
    ], [
        "After (  recording your session  ), tap the download button to save your video.",
        "The app will start the saving process. This might take some time depending on the length of the video.",
        "When it’s done, you will receive a confirmation that the video is now saved to your device.",
        "Now you are ready to return to the play mode."
    ], [
        "After (  recording your session  ), tap the share button to share your video.",
        "iOS device - It will activate the default iOS share sheet. Tap on the app you want to use and follow the instructions.",
        "Android device - It will activate the default Android share sheet. Tap on the app you want to use and follow the instructions."
    ]];
    const {id} = useParams();

    return <>
        <Helmet>
            <title>{tutorialTitles[id - 1]}</title>
        </Helmet>
        <div className="TutorialSliderScreen-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div className="TutorialSliderScreen-mainContent">
                <p className="TutorialSliderScreen-title">{tutorialTitles[id - 1]}</p>
                <div className="TutorialSliderScreen-leftArrow" onClick={() => {
                    document.getElementById('TutorialSliderScreen-slider')
                        .scrollBy({left: -379, behavior: 'smooth'});
                }}/>
                <div className="TutorialSliderScreen-rightArrow" onClick={() => {
                    document.getElementById('TutorialSliderScreen-slider')
                        .scrollBy({left: 379, behavior: 'smooth'});
                }}/>
                <div id="TutorialSliderScreen-slider" className="TutorialSliderScreen-slider">

                    {tutorial[id - 1].map((text, index) =>
                        <TutorialStep index={index} id={id} text={text} key={index} lastItem={tutorial[id - 1].length > index + 1}/>
                    )}

                </div>
            </div>
        </div>
    </>
}
