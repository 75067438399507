import './header.scss'
import FreedrumLogo from '../../assets/images/freedrum-logo.svg'
import {Redirect, Link} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import {useState, useEffect} from 'react';
import {logOut, userRequests} from "../../api/userRequests";

function mobileNavHandler() {
    let element = document.getElementById("SideNavbar-content");
    if (element.className === 'SideNavbar-content-visible') {
        element.className = '';
    } else {
        element.className = 'SideNavbar-content-visible';
    }
}

function Header({routeChanged}) {
    const [usersName, setUsersName] = useState("");
    const [logoutSuccess, setLogoutSuccess] = useState(false);

    useEffect(() => {
        userRequests((result) => {
            (result !== undefined ? setUsersName(result.name.split(" ")[0]) : setUsersName(""))
        })
    }, [setUsersName]);


    if (logoutSuccess) {
        return <Redirect to="/login"/>
    }

    return (
        <div className="Header-container">
            <p className="Header-name">Hi, {usersName}</p>
            <span className="Header-burgerMenu">
                <GiHamburgerMenu size={30} onClick={mobileNavHandler}/>
            </span>
            <span className="Header-freedrumLogo">
                <Link to="">
                    <img src={FreedrumLogo} alt="Freedrum logo"/>
                </Link>
            </span>
            <button className="Header-logout" onClick={() => logOut((value) => {
                routeChanged();
                setLogoutSuccess(value)
            })}>
                Log out
            </button>
        </div>
    );
}

export default Header;