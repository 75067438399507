import axios from "axios";
import { getAccessToken } from "./security";
import serverUrl from "../config";

export const doRequest = (method, path, params) => {
    return request(method, path, params, false)
};

export const doAuthenticatedRequest = async (method, path, params) => {
    return request(method, path, params, true)
};

const request = async (method, path, params, authenticated) => {
    let token = await getAccessToken();

    const parameters = {
        method: method,
        url: `${serverUrl}${path}`,
        data: params
    };

    if (authenticated) {
        parameters['headers'] = {
            Authentication: token
        };
    }

    return axios(parameters);

}