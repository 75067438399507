import "./feedbackcontainer.scss";
import addButton from "../../assets/images/add-button.svg";
import infoButton from "../../assets/images/info-button.svg";
import chevron from "../../assets/images/flat-chevron.svg";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import FeedbackItem from "./FeedbackItem";
import {Link} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {useEffect, useState} from "react";
import {requestAllFeedback} from "../../api/feedbackRequests";
import RemoveFeedbackModal from "../../pages/account/beta-program/feedback/RemoveFeedbackModal";

function FeedbackContainer() {
    const [feedbackMap, setFeedbackMap] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [feedbackID, setFeedbackID] = useState("");

    useEffect(() => {
        requestAllFeedback(setFeedbackMap);
    }, []);

    function showRemoveModal(feedbackId) {
        setFeedbackID(feedbackId);
        setIsOpen(true);
    }

    return (
        <div className="FeedbackContainer-container">
            <div className="FeedbackContainer-header">
                <ReactTooltip type="light" border={false} place={"right"} multiline={true}/>
                <p className="FeedbackContainer-title">
                    <span className="FeedbackContainer-title-span">Feedback</span>
                    <img src={infoButton} alt=""
                         data-tip="Private feedback, this<br />feedback only visible<br />for you and the<br />Freedrum team."
                         width="16px" height="16px"/>
                </p>
                <p className="FeedbackContainer-description">
                    Help us improve our product and leave a feedback and ratings.{" "}
                </p>
            </div>
            <div className="FeedbackContainer-addButton">
                <Link to="/account/beta-program/feedback/add">
                    <img src={addButton} alt=""/>
                </Link>
            </div>
            {Object.keys(feedbackMap).reverse().map((key, index) =>
                <div key={index} className="FeedbackContainer-mainContent">
                    <div id={`FeedbackContainer-feedback-versionInfo-${key}-${index}`}
                         className="FeedbackContainer-feedback-versionInfo"
                         style={index > 0 ? {marginTop: "48px"} : {}}>
                        <p className="FeedbackContainer-feedback-versionData">
                            <span className="FeedbackContainer-version"> {feedbackMap[key][0].appVersion.version}</span>
                            <span id="FeedbackContainer-stars-container">
                                {[...new Array(5)].map((val, index) =>
                                    Math.round(feedbackMap[key].reduce((sum, value) => {
                                        return sum + value.rating;
                                    }, 0) / feedbackMap[key].length) <= index ?
                                        <AiOutlineStar key={index} size={20}/> :
                                        <AiFillStar key={index} size={20} color="#00A21F"/>)
                                }
                            </span>
                        </p>
                        <img id={`FeedbackContainer-chevron-collapsable-${key}-${index}`} src={chevron} alt=""
                             className="FeedbackContainer-chevron-collapsable"
                             onClick={() => toggleCollapse(key, index)}/>
                    </div>
                    <div className="FeedbackContainer-collapsable" id={`FeedbackContainer-collapsable-${key}-${index}`}>
                        {feedbackMap[key].map((feedback, index) => (
                            <FeedbackItem
                                lastItem={index < feedbackMap[key].length - 1}
                                feedback={feedback}
                                key={index}
                                deleteFeedback={() => showRemoveModal(feedback.id)}
                            />
                        ))}
                        {feedbackMap[key].length > 0 &&
                        <p className="FeedbackContainer-seeAll"><Link to="/account/beta-program/feedback">See all</Link>
                        </p>
                        }
                        {feedbackMap[key].length === 0 &&
                        <p className="FeedbackContainer-noReview">No Feedback</p>
                        }
                    </div>
                </div>
            )}
            {modalIsOpen &&
            <RemoveFeedbackModal IsOpen={setIsOpen} feedbackID={feedbackID} setFeedbackMap={setFeedbackMap}/>}
        </div>

    );
}

function toggleCollapse(key, index) {
    let collapsable = document.getElementById(`FeedbackContainer-collapsable-${key}-${index}`);
    let toggle = document.getElementById(`FeedbackContainer-chevron-collapsable-${key}-${index}`);
    let versionInfo = document.getElementById(`FeedbackContainer-feedback-versionInfo-${key}-${index}`);
    if (collapsable.className === 'FeedbackContainer-collapsable FeedbackContainer-collapsed') {
        collapsable.className = 'FeedbackContainer-collapsable';
        toggle.className = 'FeedbackContainer-chevron-collapsable';
        versionInfo.className = 'FeedbackContainer-feedback-versionInfo';
    } else {
        collapsable.className = 'FeedbackContainer-collapsable FeedbackContainer-collapsed';
        toggle.className = 'FeedbackContainer-chevron-collapsable FeedbackContainer-chevron-collapsed';
        versionInfo.className = 'FeedbackContainer-feedback-versionInfo FeedbackContainer-versionInfo-collapsed';
    }
}

export default FeedbackContainer;
