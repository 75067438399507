import {doAuthenticatedRequest} from "./requests";

export function requestAllDevices(returnCache, callback) {
    doAuthenticatedRequest("GET", "/devices", {})
        .then(result => {
            localStorage.setItem("all-devices", JSON.stringify(result.data));
            callback(getAllLocalDevices());
        });
    if (returnCache) {
        callback(getAllLocalDevices());
    }
}

export function removeDevice(deviceId, callback) {
    doAuthenticatedRequest("DELETE", `/devices/${deviceId}`, {})
        .then(() => {
            let devices = localStorage.getItem("all-devices");
            if (devices != null) {
                let parsedDevices = JSON.parse(devices);
                let filteredDevices = parsedDevices.filter(value => value.id !== deviceId);
                localStorage.setItem("all-devices", JSON.stringify(filteredDevices));
            }
            callback(getAllLocalDevices());
        });
}

export function addDevice(device, callback) {
    doAuthenticatedRequest("POST", "/devices", {"deviceModel": device})
        .then((result) => {
            let devices = localStorage.getItem("all-devices");
            if (devices != null) {
                let array = JSON.parse(devices).concat(result.data);
                localStorage.setItem("all-devices", JSON.stringify(array));
            } else {
                localStorage.setItem("all-devices", JSON.stringify([result.data]));
            }
            callback(getAllLocalDevices())
        });
}

export function getAllLocalDevices() {
    let allDevices = localStorage.getItem("all-devices");
    if (allDevices !== undefined && allDevices != null) {
        let parsedDevices = JSON.parse(allDevices);
        return [
            parsedDevices.filter(device => device.model.platform === "iOS"),
            parsedDevices.filter(device => device.model.platform === "Android")
        ];
    } else {
        return [[], []];
    }
}

