import "./overview.scss";
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import addButton from "../../../../assets/images/add-button.svg";
import FeedbackContainer from "../../../../components/feedback/FeedbackContainer";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {requestAllDevices} from "../../../../api/deviceRequests";
import RemoveDeviceModal from "../device-profile/RemoveDeviceModal";
import {requestAllAppVersions} from "../../../../api/appVersionRequests";

function Overview({routeChanged}) {
    const [devicesMap, setDevicesMap] = useState([[], []]);
    const [appVersions, setAppVersions] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deviceID, setDeviceID] = useState("");

    useEffect(() => {
        requestAllDevices(true, setDevicesMap);
        requestAllAppVersions(setAppVersions)
    }, []);

    function showRemoveModal(deviceId) {
        setDeviceID(deviceId);
        setIsOpen(true);
    }

    return <>
        <Helmet>
            <title>Account</title>
        </Helmet>
        <div className="Overview-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div className="Overview-mainContent">
                <p id="Overview-topTitle">Beta Program</p>
                <div className="Overview-infoTextContainer">
                    <p className="Overview-infoTitle">Test and review our beta app.</p>
                    <p className="Overview-regularText">
                        Be part of our journey to create the best virtual drumming experience. Download the beta version
                        of our latest app and send us your feedback.
                    </p>
                </div>
                <div className="Overview-betaInfo">
                    <p id="Overview-betaInfo-title">Newest App Version</p>
                    <p id="Overview-betaInfo-version">{
                        appVersions.length !== 0 ? appVersions[0].version : ""
                    }</p>
                    <p id="Overview-betaInfo-updated">Updated {
                        appVersions.length !== 0 ?
                            new Intl.DateTimeFormat().format(Date.parse(appVersions[0].releaseDate)) :
                            ""
                    }</p>
                </div>

                <div id="Overview-img-container"/>
                <div className="Overview-belowPicture-container">
                    <div className="Overview-DeviceProfile-container">
                        <div className="Overview-DeviceProfile-header">
                            <p className="Overview-belowPicture-title">Device Profile</p>
                            <p className="Overview-belowPicture-description">
                                This is the list of the devices that you have tested.
                            </p>
                        </div>
                        <div className="Overview-addButton">
                            <Link to="/account/beta-program/device-profile/add">
                                <img src={addButton} alt=""/>
                            </Link>
                        </div>

                        {devicesMap.map((devices, index) => (
                            devices.length > 0 &&
                            <div key={index} className="Overview-DeviceProfile-list-container">
                                <div className="Overview-DeviceProfile-OS">
                                    <p className="Overview-OS-text">{index === 0 ? "iOS iPhone" : "Android Phone"}</p>
                                    <p className="Overview-count">
                                        {devices.length === 1 ? "1 device" : devices.length + " devices"}
                                    </p>
                                </div>
                                <div className="Overview-DeviceProfile-Devices">
                                    {devices.map((device, index) => (
                                            <p key={index} className="Overview-phoneModel" style={
                                                index === devices.length - 1 ?
                                                    {borderRadius: "0 0 14px 14px"} :
                                                    {marginBottom: "10px"}
                                            }>
                                                <span>{device.model.model}</span>
                                                <img className="Overview-removeDevice"
                                                     onClick={() => showRemoveModal(device.id)}
                                                     src={require('../../../../assets/images/remove-button.svg').default}
                                                     alt=""/>
                                            </p>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <FeedbackContainer/>
                </div>
            </div>
            {modalIsOpen && <RemoveDeviceModal IsOpen={setIsOpen} deviceID={deviceID} setDevicesMap={setDevicesMap}
                                               routeChanged={routeChanged}/>}
        </div>
    </>
}

export default Overview;
