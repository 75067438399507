import './addfeedbackscreen.scss'
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import {AiFillStar, AiOutlineInfoCircle, AiOutlineStar} from "react-icons/ai";
import FormInput from "../../../../components/forminput/FormInput";
import {useEffect, useState} from "react";
import SelectInput from "../../../../components/forminput/SelectInput";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {requestAllAppVersions} from "../../../../api/appVersionRequests";
import {requestAllDevices} from "../../../../api/deviceRequests";
import {addFeedback} from "../../../../api/feedbackRequests";
import {bodyValidation, isValid, nameValidation} from "../../../../components/forminput/validation";
import {handleError} from "../../../../api/errorHandling";


function AddFeedbackScreen({routeChanged}) {
    const [sendingFeedback, setSendingFeedback] = useState(false);
    const [feedback, setFeedback] = useState({device: '', appVersion: '', title: '', body: '', rating: ''});
    const [validation, setValidation] = useState({
        device: false,
        appVersion: false,
        title: false,
        body: false,
        rating: false
    })
    const [hasFocussedField, setHasFocussedField] = useState({
        device: false,
        appVersion: false,
        title: false,
        body: false,
        rating: false
    })

    const [appVersions, setAppVersions] = useState([]);
    const [devices, setDevices] = useState([]);
    const [errorCode, setErrorCode] = useState(null);
    let renderError;

    useEffect(() => {
        requestAllAppVersions((result) => setAppVersions(result));
        requestAllDevices(true, (result) => setDevices(result.flat()));
    }, []);

    if (feedback["appVersion"] === "" && appVersions.length > 0) {
        setFeedback({...feedback, appVersion: appVersions[0].id});
        setValidation({...validation, appVersion: true})
        setHasFocussedField({...validation, appVersion: true})
    }

    if (feedback["device"] === "" && devices.length > 0) {
        setFeedback({...feedback, device: devices[0].id});
        setValidation({...validation, device: true})
        setHasFocussedField({...validation, device: true})
    }

    const sendFeedback = (e) => {
        e.preventDefault();
        setHasFocussedField({
            device: true,
            appVersion: true,
            title: true,
            body: true,
            rating: true
        });

        if (!isValid(validation)) {
            if (feedback["title"] === "") {
                setErrorCode("form-missing-fields");
            } else if (feedback["body"] === "") {
                setErrorCode("form-missing-fields");
            } else if (feedback["rating"] === "") {
                setErrorCode("rating-missing")
            } else {
                setErrorCode("form-missing-fields");
            }
            return;
        }

        setErrorCode(null);

        addFeedback(feedback, () => setSendingFeedback(true), (error) => setErrorCode(error));
    }

    const handleFormChange = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setErrorCode(null);
        setHasFocussedField({...hasFocussedField, [inputName]: true});

        if (inputName === 'title') {
            setFeedback({...feedback, [inputName]: inputValue});
            if (bodyValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        } else if (inputName === 'body') {
            setFeedback({...feedback, [inputName]: inputValue});
            if (bodyValidation(inputValue)) {
                setValidation({...validation, [inputName]: true});
            } else {
                setValidation({...validation, [inputName]: false});
            }
        }

        if (inputName === 'device') {
            setFeedback({...feedback, ['device']: inputValue})
        }
    };

    if (errorCode) {
        renderError = handleError(errorCode, null);
    }

    const starsRatingHandler = (index) => {
        setFeedback({...feedback, rating: index + 1});
        setValidation({...validation, rating: true})
        setHasFocussedField({...validation, rating: true})
    }

    return <>
        <Helmet>
            <title>Beta Program - Add Feedback</title>
        </Helmet>
        <div className="AddFeedbackScreen-container">
            <Header routeChanged={routeChanged}/>
            <SideNavbar/>
            <div className="AddFeedbackScreen-mainContent">
                {sendingFeedback ?
                    <div className="AddFeedbackScreen-feedbackSend-container">
                        <p className="AddFeedbackScreen-title">Feedback submitted</p>
                        <p className="AddFeedbackScreen-feedbackSend-text">Your feedback is submitted. You will receive
                            the copy of your feedback on your email and a member
                            of our team might reach out to you for further inquiries.</p>
                        <Link to="/account/beta-program/feedback">
                            <button className="AddFeedbackScreen-backToFeedback">
                                Back to feedback
                            </button>
                        </Link>
                    </div>
                    :
                    <div><p className="AddFeedbackScreen-title">Add New Feedback</p>
                        <p className="AddFeedbackScreen-smallText">
                            <span><AiOutlineInfoCircle size={13}/></span>Private
                            feedback. This feedback only visible for you and the Freedrum team.
                        </p>
                        <form className="AddFeedbackScreen-form" autoComplete="off" onSubmit={sendFeedback}>
                            {renderError}

                            <FormInput title="Title" placeholder="Write a title" name="title" type="text"
                                       valid={validation.title || !hasFocussedField.title}

                                       handleFormChange={handleFormChange} value={""}/>
                            <SelectInput labelTitle="Version" selectName="app-version" options={appVersions}
                                         handleFormChange={handleFormChange}/>
                            <SelectInput labelTitle="Phone model" selectName="device"
                                         disabled={devices.length === 0 ? 'disabled' : ''}
                                         options={devices.length === 0 ? [{model: {model: "No devices added"}}] : devices}
                                         handleFormChange={handleFormChange}/>
                            <div className="AddFeedbackScreen-rating-container">
                                <p className="AddFeedbackScreen-ratingText">Rating:</p>
                                <div className="AddFeedbackScreen-stars-container">
                                    {[...new Array(5)].map((val, index) => feedback.rating <= index ?
                                        <span key={index} onClick={() => starsRatingHandler(index)}>
                    <AiOutlineStar
                        size={40}/></span> : <span key={index} onClick={() => starsRatingHandler(index)}>
                    <AiFillStar
                        size={40} color="#00A21F"/></span>)}
                                    <div className="AddFeedbackScreen-textArea-container">
                                        <FormInput title="Comment" placeholder="Write a comment" name="body"
                                                   type="textarea"
                                                   valid={validation.body || !hasFocussedField.body}
                                                   handleFormChange={handleFormChange} value={""}/>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="AddFeedbackScreen-save">
                                Save Feedback
                            </button>
                        </form>
                    </div>}
            </div>
        </div>
    </>
}

export default AddFeedbackScreen;
