import ReactDOM from 'react-dom';
import './removedevicemodal.scss';
import FocusTrap from "focus-trap-react";
import {removeDevice} from "../../../../api/deviceRequests";

const RemoveDeviceModal = ({IsOpen, deviceID, setDevicesMap, routeChanged}) => {
    const closingModal = () => {
        IsOpen(false);
    }

    const removeItem = () => {
        removeDevice(deviceID, (devices) => {
            routeChanged();
            setDevicesMap(devices)
        });
        IsOpen(false);
    }

    return ReactDOM.createPortal(
        <div className="modal-container">
            <FocusTrap>
                <div role="dialog" aria-modal="true" className="ReactModal__Content">
                    <div className="RemoveFeedbackModal-container">
                        <h1 className="RemoveFeedbackModal-title">Remove Device</h1>
                        <p className="RemoveFeedbackModal-body" style={{marginBottom: "0px"}}>Are you sure you want to
                            remove your device from the list? </p>
                        <button type="button" className="RemoveFeedbackModal-DeleteBtn" onClick={removeItem}>Delete
                        </button>
                        <button type="button" className="RemoveFeedbackModal-CloseBtn" onClick={closingModal}>Cancel
                        </button>
                    </div>
                </div>
            </FocusTrap>

        </div>, document.body
    );
};


export default RemoveDeviceModal;
