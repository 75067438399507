import {doAuthenticatedRequest} from "./requests";

export function userRequests(callback) {
    doAuthenticatedRequest("GET", "/users/self", {})
        .then(result => {
            localStorage.setItem("user-data", JSON.stringify(result.data));
            sendUserData(callback);
        });
    sendUserData(callback);
}

export function logOut(callback) {
    doAuthenticatedRequest("POST", "/security/signout", {})
        .then(() => {
            localStorage.clear();
            callback(true);
        })
        .catch((error) => {
                console.log(error)
            }
        )
}

function sendUserData(callback) {
    let userData = localStorage.getItem("user-data");
    if (userData !== undefined && userData !== null) {
        callback(JSON.parse(userData));
    }
}