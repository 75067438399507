import "./onboarding.scss";
import {ReactComponent as FreedrumLogo} from '../../../assets/images/freedrum-logo.svg';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const OnboardingSuccess = () => {
    return <>
        <Helmet>
            <title>Get started - Success</title>
        </Helmet>
        <div className="onboarding-container">
            <div className="onboarding-container-freedrumLogoContainer">
                <FreedrumLogo/>
            </div>
            <div className="onboarding-dialogBox-container">
                <h1>Device Added</h1>
                <p className="onboarding-success-text">
                    Your email is now submitted. The invitation can take up to 48 hours before it arrives in your inbox.
                </p>
                <Link to="">
                    <button type="button" className="onboarding-dialogBox-form-closeButton" id="onboarding-button">
                        Get started
                    </button>
                </Link>
            </div>
        </div>
    </>
};

export default OnboardingSuccess;