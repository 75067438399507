import "./deleteaccountsuccess.scss";
import {ReactComponent as FreedrumLogo} from '../../../../assets/images/freedrum-logo.svg';
import {Helmet} from "react-helmet";

const DeleteAccountSuccess = () => {

    return <>
        <Helmet>
            <title>Account Deleted - Confirmation</title>
        </Helmet>
        <div className="DeleteAccount-container">
            <div className="DeleteAccount-container-freedrumLogoContainer">
                <FreedrumLogo/>
            </div>
            <div className="DeleteAccount-dialogBox-container">
                <h1>Account Deleted</h1>
                <p>Your account is now removed from our database. </p>

                <a className="DeleteAccount-dialogBox-form-closeButton" href={"https://freedrum.rocks"}
                   target="_self"> Back to home</a>
            </div>
        </div>
    </>
};

export default DeleteAccountSuccess;