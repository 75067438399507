import {doAuthenticatedRequest} from "./requests";

export function requestAllDeviceModels(callback) {
    doAuthenticatedRequest("GET", "/device-models", {})
        .then(result => {
            localStorage.setItem("all-device-models", JSON.stringify(result.data));
            callback(getAllLocalDeviceModels());
        });
    callback(getAllLocalDeviceModels());
}

function getAllLocalDeviceModels() {
    let allDevices = localStorage.getItem("all-device-models");
    if (allDevices !== undefined && allDevices != null) {
        let parsedDevices = JSON.parse(allDevices);
        return [
            [{
                id: 0,
                platform: "iOS",
                model: "Select Device"
            }].concat(parsedDevices.filter(device => device.platform === "iOS")),
            [{
                id: 0,
                platform: "Android",
                model: "Select Device"
            }].concat(parsedDevices.filter(device => device.platform === "Android"))
        ];
    } else {
        return [[], []];
    }
}