import "./tutorialstep.scss"

import React from 'react'
import {Link} from "react-router-dom";
import rightArrow from "../../assets/images/right-arrow.svg";

export default function TutorialStep({index, id, text, lastItem}) {
    let reactStringReplace = require('react-string-replace');
    text = reactStringReplace(text, '(  setting icon  )', () => (
        <img key={"image" + index} className="TutorialStep-settings"
             src={require('../../assets/images/settings-icon.svg').default}
             alt=""/>
    ));
    text = reactStringReplace(text, '(  bar icon  )', () => (
        <img key={"image" + index} src={require('../../assets/images/sensor-bar.png').default}
             alt=""/>
    ));

    text = reactStringReplace(text, '(  save the video  )', () => (
        <Link to={'/account/tutorial/5'}><span className="TutorialStep-red">save the video</span></Link>
    ));

    text = reactStringReplace(text, '(  share it  )', () => (
        <Link to={'/account/tutorial/6'}><span className="TutorialStep-red">share it</span></Link>
    ));

    text = reactStringReplace(text, '(  recording your session  )', () => (
        <Link to={'/account/tutorial/4'}><span className="TutorialStep-red">recording your session</span></Link>
    ));

    let img = require(`../../assets/images/tutorial/tutorial${id}-${++index}.png`).default;

    return <React.Fragment key={index}>
        <div className="TutorialStep-container">
            <div className={"TutorialStep-pictureContainer"}>
                <img className={(id === '6' && index === 3) ? "TutorialStep-bodyAndroid" : "TutorialStep-body"}
                     draggable="false" src={img} alt=""/>
                <img className="TutorialStep-picture" draggable="false"
                     src={
                         (id === '6' && index === 3) ?
                             require(`../../assets/images/tutorial/tutorial-body-android.png`).default
                             :
                             require(`../../assets/images/tutorial/tutorial-body.png`).default
                     } alt=""/>
            </div>
            <div className="TutorialStep-textContainer">
                <p className="TutorialStep-circle">{index}</p>
                <p className="TutorialStep-text">
                    {text}
                </p>
            </div>
        </div>

        {lastItem ? <img className="TutorialSliderScreen-image" src={rightArrow} width={31} height={31} alt=""/> : ""}

    </React.Fragment>

}
