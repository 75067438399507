import "./devicesuccessscreen.scss";
import {useState} from "react";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";

const DeviceAddedScreen = ({routeChanged}) => {
    const [changePage, setChangePage] = useState(false);

    if (changePage) {
        return <Redirect to="/account/beta-program/device-profile?added=true"/>
    }

    const nextPage = () => {
        setChangePage(true)
    }

    return <>
        <Helmet>
            <title>Device - Success</title>
        </Helmet>
        <div className="DeviceSuccess-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div className="DeviceSuccess-mainContent">
                <h1>Device Added</h1>
                <p>Your email is now submitted. The invitation can take up to 48 hours before it arrives in your
                    inbox.</p>
                <div>
                    <button type="button" className="DeviceSuccess-dialogBox-form-closeButton"
                            id="DeviceSuccess-button"
                            onClick={nextPage}>
                        Back to device profile
                    </button>
                </div>
            </div>
        </div>
    </>
};

export default DeviceAddedScreen;