import {doRequest} from "./requests";
import {requestAllDevices} from "./deviceRequests";

export const saveCredentials = (data, callback) => {
    localStorage.setItem("access-token", data.accessToken);
    localStorage.setItem("refresh-token", data.refreshToken);
    localStorage.setItem("access-token-expiration", data.accessTokenExpiration);
    localStorage.setItem("refresh-token-expiration", data.refreshTokenExpiration);

    requestAllDevices(false, () => callback())
};

export const getAccessToken = () => {
    const now = new Date();
    const accessToken = localStorage.getItem("access-token");
    const accessExpiration = new Date(localStorage.getItem("access-token-expiration"));
    if (accessToken !== null && accessToken !== undefined && accessExpiration - now > 86_400_000) {
        return accessToken;
    }
    return null;
}

let isUpdatingAccessToken = false;

export const updateAccessToken = async () => {
    const now = new Date();
    const refreshToken = localStorage.getItem("refresh-token");
    const refreshExpiration = new Date(localStorage.getItem("refresh-token-expiration"));

    // if there's a refresh token that hasn't expired yet
    if (refreshToken != null && refreshExpiration - now > 0) {
        // Only call access token endpoint once
        if (!isUpdatingAccessToken) {
            isUpdatingAccessToken = true;
            try {
                const result = await doRequest("POST", "/security/get-access-token", {refreshToken: refreshToken});
                localStorage.setItem("access-token", result.data.accessToken);
                localStorage.setItem("access-token-expiration", result.data.accessTokenExpiration);
            } catch (error) {
                console.log(error);
            } finally {
                // Whatever happens, set to false
                isUpdatingAccessToken = false;
            }
        } else {
            // Let other endpoints wait until access token is returned
            await new Promise(resolve => {
                const interval = setInterval(() => {
                    if (!isUpdatingAccessToken) {
                        resolve();
                        clearInterval(interval);
                    }
                }, 100);
            });
        }
    }
}

export const resendCode = (vid) => {

}