import './profilescreen.scss'
import Header from "../../../../components/header/Header";
import SideNavbar from "../../../../components/side-navbar/SideNavbar";
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react';
import DeleteAccountModal from "./DeleteAccountModal";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {userRequests} from "../../../../api/userRequests";
import {doAuthenticatedRequest} from "../../../../api/requests";

function ProfileScreen({routeChanged}) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [fullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [vid, setVid] = useState("");

    useEffect(() => {
        userRequests(result =>  {
            setFullName(result.name);
            setUserName(result.username);
            setEmailAddress(result.email)
        });
    }, []);

    const openModal = (status) => {
        setDisabledStatus(true)
        setIsOpen(status);
    }

    return <>
    <HelmetProvider>
        <Helmet>
            <title>Settings - Profile</title>
        </Helmet>
        <div className="ProfileScreen-container">
            <Header routeChanged={routeChanged} />
            <SideNavbar/>
            <div className="ProfileScreen-mainContent">
                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/drumkits", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get presets
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/courses", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get courses
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/courses/1", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get course 1
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/courses/1/lessons/1", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get course 1, lesson 1
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/libraries/1/files", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get library id 1
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/courses/1/lessons/1/files", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    get files for course 1, lesson 1
                </button>
                <br />

                <button onClick={() => {
                    doAuthenticatedRequest("GET", "/courses/search?tagIds=1,2", {})
                        .then((result) => {
                            console.log("have result", result);
                        });
                }}>
                    test search
                </button>
                <br />

                <p className="ProfileScreen-title">Profile</p>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Full name</p>
                    <p className="ProfileScreen-fieldValue">{fullName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Username</p>
                    <p className="ProfileScreen-fieldValue">{userName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Email</p>
                    <p className="ProfileScreen-fieldValue">{emailAddress}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Password</p>
                    <p className="ProfileScreen-fieldValue">
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                    </p>
                </div>
                
                <Link to={{pathname: "/account/settings/profile/edit"}}> 
                    <button className="ProfileScreen-goToEdit">
                        Edit profile
                    </button>
                </Link> 
                <hr />
                <p className="ProfileScreen-deleteTitle">Delete your account</p>
                <p className="ProfileScreen-deleteInfo">The account will no longer available and all data in the account
                    will be permanently deleted. This cannot be undone.</p>
                <button className="ProfileScreen-delete" onClick={() => {
                    doAuthenticatedRequest("POST", "/security/delete-account-step1", {})
                        .then((result) => {
                            setVid(result.data.vid);
                            openModal(true);
                        })
                }}>
                    Delete account
                </button>
            </div>
            <div>
                {modalIsOpen &&
                <DeleteAccountModal vid={vid} openModal={() => openModal(false)} setDisabledStatus={setDisabledStatus} email={emailAddress}/>}
            </div>
        </div>
        </HelmetProvider>
    </>
}

export default ProfileScreen;
