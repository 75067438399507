import FormInput from "../forminput/FormInput";
import React, {useEffect, useState} from "react";
import {doAuthenticatedRequest} from "../../api/requests";
import {emailValidation, isValid} from "../forminput/validation";
import ChangeEmailModal from "../../pages/account/settings/profile/ChangeEmailAddressModal";
import {handleError} from "../../api/errorHandling";

export function ChangeEmailComponent({setDisabledStatus, disabledStatus, emailModel, setEmailModel, routeChanged}) {
    const [validation, setValidation] = useState({email: false});
    const [hasFocussedField, setHasFocussedField] = useState({email: false});
    const [changeEmailModalIsOpen, setChangeEmailModalIsOpen] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [modalName, setModalName] = useState("verify");
    const [vid, setVid] = useState("");

    let renderError;

    useEffect(() => {
        if (!changeEmailModalIsOpen) {
            setDisabledStatus(false)
        }
    }, [changeEmailModalIsOpen, setDisabledStatus])

    const changeEmail = (e) => {
        e.preventDefault();

        setHasFocussedField({...hasFocussedField, email: true})

        if (emailModel.email === emailModel.previousEmail) {
            setErrorCode("same-email");
            return;
        }

        if (!isValid(validation)) {
            setErrorCode("email-invalid")
            return;
        }

        setErrorCode(null);

        doAuthenticatedRequest("POST", "/security/change-email-step1", {newEmail: emailModel.email})
            .then(result => {
                setVid(result.data.vid);
                setDisabledStatus(true);
                setChangeEmailModalIsOpen(true);
            })
            .catch(error => setErrorCode(error));
    }

    const emailFormHandler = (input) => {
        let inputName = input.name
        let inputValue = input.value;

        setEmailModel({...emailModel, [inputName]: inputValue});
        if (emailValidation(inputValue)) {
            setValidation({...validation, [inputName]: true});
        } else {
            setValidation({...validation, [inputName]: false});
        }
    }

    if (errorCode !== null) {
        renderError = handleError(errorCode, null);
    }

    return <>
        <p className="EditProfileScreen-title">Change Email Address</p>
        <p className="EditProfileScreen-info">You will need to access the email account currently associated with your
            Freedrum account in order to make this change.</p>
        <p className="EditProfileScreen-info">By changing your email address, you will no longer have access to the beta
            app with the email address previously associated with your Freedrum account. You will have access to the
            beta app with the new email address you use with your Freedrum account.</p>
        <div style={{marginTop: "16px"}}>
            {renderError}
        </div>
        <form className="EditProfileScreen-form" onSubmit={changeEmail}>
            <FormInput title="Current Email Address" name="previousEmail" handleFormChange={emailFormHandler}
                       type='email' valid={true} disabledStatus={true} value={emailModel.previousEmail}/>
            <FormInput title="New Email Address" name="email" handleFormChange={emailFormHandler}
                       type='email' valid={validation.email || !hasFocussedField.email}
                       disabledStatus={disabledStatus} value={emailModel.email}
                       placeholder={"Enter your new email address"}/>
            <button type="submit" className="EditProfileScreen-sendProfileButton">
                Change email
            </button>
        </form>
        {changeEmailModalIsOpen &&
        <ChangeEmailModal openModal={setChangeEmailModalIsOpen} modalName={modalName} vid={vid}
                          setModalName={setModalName} setDisabledStatus={setDisabledStatus}
                          emailModel={emailModel} routeChanged={routeChanged}/>}
    </>;
}